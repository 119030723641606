import React from "react"
import Section01 from "./section01/index.pl"
import Intro from "./intro/index.pl"
import Contact from "./contact/index.pl"
import CookieConsent, { Cookies } from "react-cookie-consent";
import InfoDiv from '../components/InfoDiv'
import Section02 from "./gallery/index.pl"
import Section03 from "./b2b/index.pl"
import Section04 from "./swiece/index.pl"
import Layout from "../layouts/pl"
const language = 0


Cookies.get("gatsby-cookies")===null?Cookies.set('gatsby-gdpr-google-analytics', 'true'):
(
  !Cookies.get("gatsby-cookies")?
    Cookies.set('gatsby-gdpr-google-analytics', 'true'):
    Cookies.set('gatsby-gdpr-google-analytics', 'false')
)


const IndexPage = () => (
  <Layout>

  <CookieConsent
      location="bottom"
      buttonText="Akceptuję"
      declineButtonText="Odrzucam"
      cookieName="gatsby-cookies"
      acceptOnScroll={true}
      style={{ background: "rgba(0,0,0,.8)" }}
      buttonStyle={{ background: "green", fontSize: "12px" }}
      declineButtonStyle={{background: "red",fontSize:"12px"}}
      expires={14}
      contentClasses="content"
      enableDeclineButton
      onDecline={() => {
        Cookies.remove('_ga')
        Cookies.remove('_gat')
        Cookies.remove('_gid')
        Cookies.set('gatsby-gdpr-google-analytics', 'false')
      }}
      onAccept={() => {
          Cookies.set('gatsby-gdpr-google-analytics', 'true')
      }}
  >
      Ten serwis używa plików cookies. Brak zmiany ustawień przeglądarki oznacza zgodę na ich użycie.{"  "}
      <InfoDiv element="pp"/>
  </CookieConsent>

    <div id={`starter_div`}>
      <Intro />

      <Section01 />

      <Section02 lang={language} seoImg="Kwiaciarnia Alma Wrocław" />

      <Section03 lang={language} />

      <Section04 lang={language} />

      <Contact lang={language} />
    </div>
  </Layout>
)

export default IndexPage
